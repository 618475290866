// src/pages/CuentaVirtualUsa.js
import React, { useState } from 'react';
import { useAuthContext } from '../hooks';
import axios from 'axios';

const CuentaVirtualUsa = () => {
  const { user } = useAuthContext();
  const [tosLink, setTosLink] = useState(null);
  const [customerData, setCustomerData] = useState(null);

  const getTosLink = async () => {
    try {
      const response = await axios.post('https://api.sandbox.bridge.xyz/v0/customers/tos_links', {}, {
        headers: {
          'Idempotency-Key': 'your-unique-idempotency-key',  // Genera una clave de idempotencia única
          'Api-Key': 'sk-test-8dae18db3d1065bd60414d72cf2f55fa',
        }
      });
      setTosLink(response.data.url);
    } catch (error) {
      console.error('Error getting TOS link:', error);
    }
  };

  const createCustomer = async (signedAgreementId) => {
    try {
      const response = await axios.post('https://api.sandbox.bridge.xyz/v0/customers', {
        first_name: 'John',
        last_name: 'Doe',
        email: 'johndoe@johndoe.com',
        address: {
          street_line_1: '123 Washington St',
          street_line_2: 'Apt 2F',
          city: 'San Francisco',
          state: 'CA',
          postal_code: '10001',
          country: 'USA'
        },
        birth_date: '1989-09-09',
        tax_identification_number: '111-11-1111',
        phone: '+15555555555',
        signed_agreement_id: signedAgreementId,
        type: 'individual'
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Api-Key': 'sk-test-8dae18db3d1065bd60414d72cf2f55fa',
          'Idempotency-Key': 'your-unique-idempotency-key',  // Genera una clave de idempotencia única
        }
      });
      setCustomerData(response.data);
    } catch (error) {
      console.error('Error creating customer:', error);
    }
  };

  return (
    <div className='app__container p-4 my-3'>
      <h6 className='mb-4 fw-bold text-purple'>
        Hola, {user.name}!
      </h6>
      <h2 className='mb-4 fw-bold'>
        $ 0.00
      </h2>
      <button onClick={getTosLink}>Obtener enlace de Términos de Servicio</button>
      {tosLink && (
        <div>
          <h3>Enlace de TOS:</h3>
          <a href={tosLink} target="_blank" rel="noopener noreferrer">Aceptar Términos de Servicio</a>
        </div>
      )}
      <button onClick={() => createCustomer('signed_agreement_id')}>Crear Cliente</button>
      {customerData && (
        <div>
          <h3>Cliente creado:</h3>
          <pre>{JSON.stringify(customerData, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default CuentaVirtualUsa;
