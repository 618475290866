// src/routes/PrivateRoutes.js
import React from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import { Header, Sidebar } from "../components";
import { Historial, Dashboard, Perfil, CuentaVirtualUsa } from "../pages"; // Importa el nuevo componente
import { UIProvider } from '../context';

export const PrivateRoutes = () => {
  return (
    <>
      <UIProvider>
        <div style={{ minHeight: '100vh' }}>
          <Header />
          <div className="container-fluid h-100">
            <div className="row">
              <Sidebar />
              <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 py-5">
                <Routes>
                  {/* Dashboard */}
                  <Route path="/" element={<Dashboard />} />
                  {/* Historial */}
                  <Route path="/historial" element={<Historial />} />
                  {/* mi-cuenta */}
                  <Route path="/perfil" element={<Perfil />} />
                  {/* Cuenta Virtual USA */}
                  <Route path="/cuenta-virtual-usa" element={<CuentaVirtualUsa />} />
                  {/* DEFAULT */}
                  <Route path="*" element={<Navigate to='/' />} />
                </Routes>
              </main>
            </div>
          </div>
        </div>
      </UIProvider>
    </>
  );
};
