import React, { useEffect, useRef, useState } from 'react';
import { useAuthContext } from '../hooks';
import axios from 'axios';

export const Widget = () => {

  const [queryParameters, setQueryParameters] = useState({
    address: "",
    companyId: "66194c6fc2faf00007d4a59d",
    coin: "",
    network: "",
    ramp: "",
    userId: '',
    fiatAmount: "",
    cryptoAmount: "",
    successRedirectUrl: "https://menlei.net",
    externalId: "",
  });

  const [option, setOption] = useState('pesos');
  const [disabled, setDisabled] = useState(true);
  const [iframeSrc, setIframeSrc] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [price, setPrice] = useState(1050); // Precio inicial de la API
  const { user } = useAuthContext();
  const form = useRef();

  useEffect(() => {
    if (user) {
      setQueryParameters(params => ({ ...params, userId: user.uid }));
    }
  }, [user]);

  useEffect(() => {
    if (form.current) {
      setDisabled(false);
      form.current.querySelectorAll('input, select').forEach(element => {
        if (!element.value && element.hasAttribute('required')) {
          setDisabled(true);
        }
      });
    }
  }, [queryParameters, option]);

  useEffect(() => {
    // Función para obtener el precio desde la API
    const fetchPrice = async () => {
      try {
        const { data } = await axios.get('/v1/price/USDT_ARS');
        setPrice(data.sell);
      } catch (error) {
        console.error("Error fetching price from API", error);
      }
    }

    fetchPrice();
  }, []);

  const handleInput = e => {
    setQueryParameters(params => ({ ...params, [e.target.name]: e.target.value }));
  }

  const handleConvert = () => {
    setShowForm(true);
  }

  const handleSubmit = e => {
    e.preventDefault();
    const url = new URL("https://ramp-qa.manteca.dev/auth/w-signup/id");
    option === 'pesos' ? delete queryParameters.cryptoAmount : delete queryParameters.fiatAmount;
    Object.keys(queryParameters).forEach(key => {
      if (queryParameters[key]) {
        if (key === 'cryptoAmount') {
          url.searchParams.append(key, queryParameters.cryptoAmount * (10 ** 18));
        } else {
          url.searchParams.append(key, queryParameters[key]);
        }
      }
    });
    setIframeSrc(url.href);
  }

  const handleChangeOption = e => {
    setOption(e.target.value);
  }

  const getConvertedAmount = () => {
    if (option === 'pesos') {
      return (queryParameters.cryptoAmount * price).toFixed(2);
    } else {
      return (queryParameters.fiatAmount / price).toFixed(6);
    }
  }

  return (
    <>
      {
        !iframeSrc && !showForm && (
          <div className='conversion-widget'>
            <div className='conversion-widget__row'>
              <div className='conversion-widget__col'>
                <label>Envías</label>
                <input
                  type="number"
                  name="cryptoAmount"
                  value={queryParameters.cryptoAmount}
                  onInput={handleInput}
                  required
                  className='conversion-widget__input'
                />
                <select
                  name="coin"
                  value={queryParameters.coin}
                  onChange={handleInput}
                  className='conversion-widget__select'
                >
                  <option value="USDT">USDT</option>
                  <option value="USDC">USDC</option>
                  <option value="ARS">Pesos Argentinos</option>
                </select>
              </div>
              <div className='conversion-widget__col'>
                <label>Recibes</label>
                <input
                  type="number"
                  name="fiatAmount"
                  value={getConvertedAmount()}
                  readOnly
                  className='conversion-widget__input'
                />
                <select
                  name="coin"
                  value={option === 'pesos' ? 'ARS' : queryParameters.coin}
                  onChange={handleChangeOption}
                  className='conversion-widget__select'
                >
                  <option value="USDT">USDT</option>
                  <option value="USDC">USDC</option>
                  <option value="ARS">Pesos Argentinos</option>
                </select>
              </div>
            </div>
            <button
              type="button"
              className="conversion-widget__button"
              onClick={handleConvert}
            >
              Convertir
            </button>
          </div>
        )
      }
      {
        !iframeSrc && showForm && (
          <div className='animate__animated animate__fadeIn'>
            <div className='row'>
              <div className='col-12'>
                <form onSubmit={handleSubmit} ref={form}>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="network">Red a través de la cual se envía la cripto</label>
                    <select
                      name="network"
                      id="network"
                      className="form-select"
                      onChange={handleInput}
                      required
                    >
                      <option value="BSC">BSC</option>
                      <option value="POLYGON">POLYGON</option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <label className="form-label" htmlFor="address">Destino donde se desea recibir las cryptos (wallet)</label>
                    <input
                      type="text"
                      name="address"
                      id="address"
                      className="form-control"
                      onInput={handleInput}
                      required
                    />
                  </div>

                  <button
                    type="submit"
                    className="btn btn--purple"
                    disabled={disabled}
                  >
                    Enviar
                  </button>
                </form>
              </div>
            </div>
          </div>
        )
      }

      {
        iframeSrc && (
          <div className='app__container p-3 my-3'>
            <iframe
              src={iframeSrc}
              name="manteca_widget"
              title="Manteca Widget"
              id='manteca-iframe'
              className='w-100'
              style={{
                minHeight: '600px'
              }}
            ></iframe>
          </div>
        )
      }
    </>
  )
}

export default Widget;
